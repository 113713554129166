[class*='MaterialNestedMenu-action-']:not(.MaterialNestedMenu-actionButton-16) {
  margin-left: 0 !important;
  margin-right: auto !important;
}

[class*='MaterialNestedMenu-listItem-'] {
  font-family: 'Arial', sans-serif;
}

[class*='MaterialNestedMenu-listItemSelected-'] {
  font-family: 'Arial', sans-serif;
}

[class*='MaterialNestedMenu-rowItem-'] {
  font-family: 'Arial', sans-serif;
}

[class*='MaterialNestedMenu-lv2Item-'] {
  padding-left: 0 !important;
  padding-right: 2rem !important;
}

[class*='MaterialNestedMenu-lv2RowItem-'] {
  padding-left: 0 !important;
  padding-right: 2rem !important;
}

[class*='MaterialNestedMenu-lv3Item-'] {
  padding-left: 0 !important;
  padding-right: 3rem !important;
}

/*ul [class*="MaterialNestedMenu-list-"]:before {*/
/*  top: 14px;*/
/*  right: 1rem;*/
/*  width: 2px;*/
/*  bottom: 14px;*/
/*  content: " ";*/
/*  z-index: 1;*/
/*  position: absolute;*/
/*  background-color: rgba(0,0,0,0.08);*/
/*}*/

/*[class*="MaterialNestedMenu-lv2Item-"]:after {*/
/*  top: 50%;*/
/*  right: 2rem;*/
/*  width: 2px;*/
/*  height: 40%;*/
/*  content: " ";*/
/*  opacity: 0;*/
/*  z-index: 2;*/
/*  position: absolute;*/
/*  transform: translateY(-50%);*/
/*  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;*/
/*}*/

[class*='MaterialNestedMenu-lv3List-']:before {
  top: 14px;
  right: 2rem;
  width: 2px;
  bottom: 14px;
  content: ' ';
  z-index: 1;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.08);
}

[class*='MaterialNestedMenu-lv3Item-']:after {
  top: 50%;
  right: 2rem;
  width: 2px;
  height: 40%;
  content: ' ';
  opacity: 0;
  z-index: 2;
  position: absolute;
  transform: translateY(-50%);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

[class*='MaterialNestedMenu-lv2RowItem-'] {
  padding-left: 1rem !important;
}

[class*='makeStyles-drawer-']::-webkit-scrollbar {
  width: 0;
}
