body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f7f7f7 !important;
}

.swal2-content,
.swal2-title,
.swal2-confirm,
.swal2-cancel {
  font-family: "Tahoma", sans-serif;
}
.swal2-container {
  z-index: 99999 !important;
}
.bg-white {
  background-color: #fff !important;
}
.MuiTable-root.MuiTableCell-body.MuiTableCell-alignLeft {
  font-family: "Tahoma", sans-serif !important;
}

.MuiTable-root.MuiTableCell-body.MuiTableCell-alignCenter {
  font-family: "Tahoma", sans-serif !important;
}

.MuiTable-root.MuiTableCell-body.MuiTableCell-alignCenter {
  font-family: "Tahoma", sans-serif !important;
}

#root {
  height: 100%;
}

#basket .MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  background-color: #dddddd61;
}

.MuiCollapse-entered .MuiButtonBase-root .MuiSvgIcon-root {
  margin-left: 10px;
}
// .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
//   margin-left: 0;
//   left: 10px
// }
.MuiDrawer-root .MuiPaper-root > div {
  background-color: rgb(11, 37, 69);
}
header.MuiPaper-root {
  background-color: #fbbd2f;
}
.MuiList-root .MuiListItemText-root span{
  color: #fff;
}
.MuiList-root .MuiListItemIcon-root {
  color: #fff;
}
.MuiList-root .MuiSvgIcon-root {
  color: #fff;
}

.leaflet-control-geocoder-alternatives-minimized {
  display: block;
  height: 100px;
  overflow-y: scroll;
}
aside.ant-layout-sider .ant-select.ant-select-auto-complete {
  width: 170px;
}
@media (min-width:300px) and (max-width: 1280px){
  .MuiDrawer-paper {
    top: 56px !important;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 5px !important; 
  }
  aside.ant-layout-sider {
    width: 150px !important;
    min-width: 150px !important;
    max-width: 150px !important;
  }
  aside.ant-layout-sider .ant-select.ant-select-auto-complete {
    width: 120px;
  }
}
.chat{
  min-height: 100% !important;
  height: auto !important;
  padding-bottom: 140px !important;
}
.chat li.ant-list-item:last-child{
  margin-bottom: 60px;
}

.employee-btn a#backToList {
  width: 190px !important;
}
.leaflet-routing-collapse-btn {
  display: block;
}
.leaflet-routing-collapse-btn:after {
  color: #000;
  z-index: 100000;
}
@media (min-width:100px) and (max-width: 700px){
  li.MuiImageListItem-root {
    width: 33% !important;
  }
  .chat li.MuiImageListItem-root {
    width: 100% !important;
  }
}
.leaflet-routing-container.leaflet-bar.leaflet-routing-collapsible.leaflet-control {
  display: none;
}
.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
  background-color: rgb(184 184 184 / 20%) !important;
}

.chat .ant-list-footer {
  position: absolute;
  bottom: 40px;
  // bottom: 40px;
  // max-width: 100%;
}
.editBtn {
  font-size: 36px!important;
}
.marker-cluster {
  display: flex!important;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  background: red;
  border-radius: 50%;
  z-index: 9999;
}