/* @media (min-width:1200px){
    .mainContent {
        margin-left: -213px !important;
    }
}
@media (min-width:300px) and (max-width: 1200px){
    .mainContent {
        margin-left: 80px !important;
    }
} */
@media (max-width: 480px){
    .resizeInput #amount, #amountExpenses{
        padding: 10px;
    }
} 
/* .makeStyles-shiftContent-2 {
    padding-left: 70px !important;
} */
.slick-list {
    max-height: calc(100vh - 250px);
}

.routing.leaflet-tooltip {
    position: absolute;
    padding: 4px 8px;
    background-color: #f90808;
    border: 1px solid #fff;
    border-radius: 3px;
    color: #fff;
    white-space: nowrap;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
    border-radius: 101%;
    font-weight: bold;
}
