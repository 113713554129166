@mixin flex-display($align-items, $justify-content, $flex-wrap: nowrap, $flex-direction: row) {
  display: flex;
  align-items: $align-items;
  justify-content: $justify-content;
  flex-direction: $flex-direction;
  flex-wrap: $flex-wrap;
}

@mixin unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
